import React from "react"
import { graphql } from "gatsby"
import style from "./blog-quote.mod.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { Block, Container } from "../components"

const BlogQuote = ({ text, person }) => {
  const classes = [style.blog_quote]

  return (
    <Container maxWidth="inner">
      <Block gutters>
        <div className={classes.join(" ")}>
          <div className={style.blog_quote__text}>"{text}"</div>
          {person && (
            <div className={style.blog_quote__person}>
              {person.image && (
                <div className={style.blog_quote__image}>
                  <GatsbyImage image={person.image.gatsbyImageData} />
                </div>
              )}
              <div>
                <div className={style.blog_quote__person_name}>
                  {person.name}
                </div>
                <div className={style.blog_quote__person_title}>
                  {person.jobTitle}
                </div>
              </div>
            </div>
          )}
        </div>
      </Block>
    </Container>
  )
}

export default BlogQuote

export const query = graphql`
  fragment BlogQuote on DatoCmsBlogQuote {
    id
    __typename
    text
    person {
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: {
            h: "121"
            fit: "crop"
            w: "121"
            q: 60
            auto: "format"
          }
        )
        alt
      }
      name
      jobTitle
      originalId
    }
    padding
    model {
      apiKey
    }
  }
`
