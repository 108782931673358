import React from "react"
import { graphql } from "gatsby"
import style from "./blurb-grid.mod.scss"
import { Card, Container } from "../components"

const BlurbGrid = ({ items, layout }) => {
  const classes = [style.blurbs]

  layout === "3-wide" && classes.push(`${style.blurbs}--3-wide`)

  return (
    <Container>
      <div className={classes.join(" ")}>
        {items.map(item => (
          <Card
            elevated
            image={item.image}
            content={item.content}
            blurb
            link={
              item.optionalLink?.link ||
              (item.optionalLink?.url && { url: item.optionalLink?.url })
            }
            localLink={item.optionalLink?.url}
          />
        ))}
      </div>
    </Container>
  )
}

export default BlurbGrid

export const query = graphql`
  fragment BlurbGrid on DatoCmsBlurbGrid {
    id
    __typename
    padding
    items {
      id
      image {
        gatsbyImageData(
          layout: FULL_WIDTH
          imgixParams: { h: "620", fit: "crop", w: "1140", q: 80 }
        )
        alt
      }
      content
      optionalLink {
        ...Link
      }
    }

    layout
    model {
      apiKey
    }
  }
`
