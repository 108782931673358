import React from "react"
import style from "./filter.mod.scss"
import { FaRedo } from "@react-icons/all-files/fa/FaRedo"
import { Button, Select } from "@components"
import Tile from "../components/tile"

const Filter = ({
  onClick,
  onChange,
  filters = [],
  displayText,
  tiles,
  locationOptions: clearanceTagOptions = []
}) => {
  const handleTileClick = (key, value) => {
    const event = {
      target: {
        name: key, // Key corresponding to the filter type
        value: value // Value to filter by (e.g., category)
      }
    }
    onChange(event)
  }

  return (
    <>
      {/* Render tiles filtered by clearanceTag */}
      {tiles?.length > 0 && (
        <div className={style.filter__tiles}>
          {tiles
            .filter(tile => {
              const tileTag = tile.name // Assuming tile.name holds the clearance tag
              const normalizedTileTag = String(tileTag).toLowerCase()
              const normalizedOptions = clearanceTagOptions.map(option =>
                String(option.value).toLowerCase()
              )
              const match = normalizedOptions.includes(normalizedTileTag)
              console.log(
                `Checking "${normalizedTileTag}" against`,
                normalizedOptions,
                `=> Match: ${match}`
              )
              return match
            })
            .map(tile => (
              <Tile
                {...tile}
                key={tile.id}
                onClick={() => handleTileClick("clearanceTag", tile.name)}
              />
            ))}
        </div>
      )}
      <div className={style.filter}>
        {filters.length > 0 && (
          <div className={style.filter__selects}>
            {filters.map(filter => (
              <Select
                {...filter}
                value={filter.value}
                row={filters.length > 1}
                fullWidth={filters.length <= 1}
                strongBorder
                key={`select--${filter.name}`}
                onChange={onChange}
              />
            ))}
          </div>
        )}
        <div className={style.filter__controls}>
          <Button
            type="reset"
            onClick={onClick}
            children={<FaRedo />}
            secondary
            white
            circular
          />
        </div>
      </div>
    </>
  )
}

export default Filter
