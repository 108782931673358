import React from "react"
import PropTypes from "prop-types"
import ThemeContext, { isBrowser } from "@context/themeContext"
import { useStaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"
import {
  Header,
  Ticker,
  Footer,
  Swirl,
  Drilldown,
  PreFooter,
  BackToTop,
  GlobalSearchWrapper
} from "@components"
import Headroom from "react-headroom"
import { menuTreeFromPages } from "@helpers/menuHelpers"
import FocusLock from "react-focus-lock"
import { motion } from "framer-motion"
import { JsonLd } from "react-schemaorg"
import HeaderControl from "../partials/components/header-control"

const mql = isBrowser() ? window.matchMedia("(min-width: 1024px)") : false

const mqlTablet = isBrowser() ? window.matchMedia("(max-width: 1024px)") : false

const SearchResultsLayout = ({
  minimal = false,
  children,
  showHeader = true
}) => {
  const {
    site,
    allSitePage,
    auMegaMenu,
    nzMegaMenu,
    content
  } = useStaticQuery(graphql`
    {
      content: contentYaml {
        faq
        customerSupport
      }
      site {
        siteMetadata {
          lang
          checkoutURL
          siteUrl
          facebook
          instagram
          twitter
          pinterest
          title
          logo
          description
          phoneNumber
        }
      }
      allSitePage {
        nodes {
          ...MenuHelpersAllPages
        }
      }
      auMegaMenu: allDatoCmsMegaMenu(locale: "en-AU") {
        nodes {
          id
          locales
          position
          title
          setToEnd
          category {
            ... on DatoCmsProductLandingPage {
              id
              slug
              title
              path
            }
            ... on DatoCmsPage {
              id
              slug
              title
              path
            }
          }
          columns {
            ... on DatoCmsMenuLearnColumn {
              __typename
              id
              heading
              linkPath
              placeholderImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  imgixParams: {
                    h: "182"
                    w: "362"
                    fit: "crop"
                    auto: "format"
                  }
                )
                alt
              }
              collections: links {
                ... on DatoCmsLearnSubcategory {
                  id
                  title: name
                  slug
                  parentCategory {
                    slug
                    __typename
                  }
                }
                ... on DatoCmsLearnCategory {
                  id
                  title
                  slug
                }
                ... on DatoCmsPage {
                  id
                  title
                  slug
                  path
                }
              }
              tile {
                ...Tile
              }
            }
            ... on DatoCmsMenuColumn {
              __typename
              id
              heading
              collections {
                ... on DatoCmsCollection {
                  __typename
                  id
                  name
                  slug
                  path
                }
                ... on DatoCmsCustomCollection {
                  __typename
                  id
                  name
                  link {
                    ...Link
                  }
                }
              }
              tile {
                ...Tile
              }
            }
          }
        }
      }
      nzMegaMenu: allDatoCmsMegaMenu(locale: "en-NZ") {
        nodes {
          id
          locales
          position
          title
          setToEnd
          category {
            ... on DatoCmsProductLandingPage {
              id
              slug
              title
              path
            }
            ... on DatoCmsPage {
              id
              slug
              title
              path
            }
          }
          columns {
            ... on DatoCmsMenuLearnColumn {
              __typename
              id
              heading
              linkPath
              placeholderImage {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  imgixParams: {
                    h: "182"
                    w: "362"
                    fit: "crop"
                    auto: "format"
                  }
                )
                alt
              }
              collections: links {
                ... on DatoCmsLearnSubcategory {
                  id
                  title: name
                  slug
                  parentCategory {
                    slug
                    __typename
                  }
                }
                ... on DatoCmsLearnCategory {
                  id
                  title
                  slug
                }
                ... on DatoCmsPage {
                  id
                  title
                  slug
                  path
                }
              }
              tile {
                ...Tile
              }
            }
            ... on DatoCmsMenuColumn {
              __typename
              id
              heading
              collections {
                ... on DatoCmsCollection {
                  __typename
                  id
                  name
                  slug
                  path
                }
                ... on DatoCmsCustomCollection {
                  __typename
                  id
                  name
                  link {
                    ...Link
                  }
                }
              }
              tile {
                ...Tile
              }
            }
          }
        }
      }
      allDatoCmsGlobal {
        nodes {
          id
          contactPage {
            id
          }
        }
      }
    }
  `)
  const siteLanguage = process.env.GATSBY_LANGUAGE || "en-AU"
  const allDatoCmsMegaMenu = siteLanguage === "en-AU" ? auMegaMenu : nzMegaMenu

  const menuItems = menuTreeFromPages(
    allSitePage.nodes,
    content,
    allDatoCmsMegaMenu.nodes
  )

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at calc(100% - 35px) 40px)`,
      transition: {
        type: "spring",
        stiffness: 20,
        restDelta: 2
      }
    }),
    closed: {
      clipPath: "circle(0px at calc(100% - 32px) 48px)",
      transition: {
        delay: 0.5,
        type: "spring",
        stiffness: 400,
        damping: 40
      }
    }
  }

  const sidebarWrapper = {
    open: {
      display: "block"
    },
    closed: {
      display: "none",
      transition: {
        delay: 1.5
      }
    }
  }

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: site.siteMetadata.title,
    description: site.siteMetadata.description,
    telephone: site.siteMetadata.phoneNumber,
    contactPoint: [
      {
        "@type": "ContactPoint",
        telephone: site.siteMetadata.phoneNumber,
        contactType: "customer service"
      }
    ],
    url: site.siteMetadata.siteUrl,
    logo: site.siteMetadata.logo,
    sameAs: [
      site.siteMetadata.facebook,
      site.siteMetadata.instagram,
      site.siteMetadata.twitter,
      site.siteMetadata.pinterest
    ]
  }

  return (
    <>
      <HelmetDatoCms>
        <meta name="format-detection" content="telephone=no" />
        <html lang={site.siteMetadata.lang} />
      </HelmetDatoCms>
      <ThemeContext.Consumer>
        {({
          isSidebarOpen,
          closeSidebar,
          toggleSidebar,
          isCartOpen,
          isSearchOpen,
          setSearchOpen
        }) => {
          minimal && isSidebarOpen && closeSidebar()
          mql && mql.addListener(({ matches }) => matches && closeSidebar())
          const classes = ["layout"]

          minimal && classes.push("layout--minimal")
          classes.push(`layout--sidebar-${isSidebarOpen ? "open" : "close"}`)
          classes.push(`layout--cart-${isCartOpen ? "open" : "close"}`)
          classes.push(`layout--search-${isSearchOpen ? "open" : "close"}`)

          return (
            <div id="layout" className={classes.join(" ")}>
              <GlobalSearchWrapper>
                {showHeader && (
                  <>
                    <FocusLock group="sidebar" disabled={!isSidebarOpen}>
                      <Headroom
                        disableInlineStyles
                        className={
                          isSidebarOpen ? "headroom-wrapper--sidebar" : ""
                        }
                      >
                        <HeaderControl
                          minimal={minimal}
                          toggleSidebar={toggleSidebar}
                          isSidebarOpen={isSidebarOpen}
                          isSearchOpen={isSearchOpen}
                          setSearchOpen={setSearchOpen}
                          isBrowser={isBrowser}
                        />
                        <Header
                          minimal={minimal}
                          menuItems={menuItems}
                          toggleSidebar={toggleSidebar}
                          isSidebarOpen={isSidebarOpen}
                          isSearchOpen={isSearchOpen}
                          setSearchOpen={setSearchOpen}
                          isBrowser={isBrowser}
                        />
                        {!minimal && mqlTablet.matches && (
                          <BackToTop element={"#___gatsby"} type="id" />
                        )}
                      </Headroom>
                    </FocusLock>
                    {!minimal && <Ticker />}
                  </>
                )}
                <Swirl />
                <div className={"layout__content"}>
                  <main>{children}</main>
                </div>

                {!minimal && <PreFooter />}
                <Footer minimal={minimal} />
                <motion.div
                  className={"layout__sidebar-bg-wrapper"}
                  initial="closed"
                  variants={sidebarWrapper}
                  animate={isSidebarOpen ? "open" : "closed"}
                >
                  <motion.div
                    dragPropagation
                    animate={isSidebarOpen ? "open" : "closed"}
                    className={"layout__sidebar-bg"}
                    variants={sidebar}
                  />
                </motion.div>
                <motion.div
                  className={"layout__sidebar"}
                  initial={false}
                  animate={isSidebarOpen ? "open" : "closed"}
                  variants={{
                    open: {
                      display: "block"
                    },
                    closed: {
                      transition: {
                        delay: 1
                      },
                      display: "none"
                    }
                  }}
                >
                  <motion.div className={"layout__sidebar-scroll"}>
                    <FocusLock group="sidebar" disabled={!isSidebarOpen}>
                      <Drilldown
                        isVisible={isSidebarOpen}
                        items={menuItems}
                        closeSidebar={closeSidebar}
                      />
                    </FocusLock>
                  </motion.div>
                </motion.div>
              </GlobalSearchWrapper>
            </div>
          )
        }}
      </ThemeContext.Consumer>
      <JsonLd item={schema} />
    </>
  )
}

SearchResultsLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default SearchResultsLayout
