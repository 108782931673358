export const noIndexPaths = [
  "/swim-spas/swim-spas/",
  "/swim-spas/luxury/",
  "/swim-spas/premium/",
  "/swim-spas/mid-level/",
  "/spa-pools/family-spa-pools/"
]

export const shouldNoIndex = pathname => {
  return noIndexPaths.includes(pathname)
}
